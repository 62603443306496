<script lang="ts">
import { Block, Page } from "@claimsgate/core-types";
import Vue, { PropOptions } from "vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockPageButtons from "@/components/shared/blocks/pagebuttons.vue";

import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";

import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";
import { getBlockComponent } from "@/blockComponentRegistry";

export default Vue.extend({
  name: "BlockRender",
  components: {
    BlockButton,
    BlockPageButtons,
    BlockInvalidFeedback,
    BlockSubtitle,
  },
  props: {
    block: { type: Object } as PropOptions<Block>,
    builder: { type: Boolean } as PropOptions<boolean>,
    page: { type: Object } as PropOptions<Page>,
    inputErrors: { type: Number, default: 0 } as PropOptions<number>,
  },
  data() {
    return {
      showBlocks: true,
      funnelId: this.$route.params.funnelId,
      dynamicComponent: null,
    };
  },
  methods: {
    emitEvent(name: string, params?: Record<string, any>) {
      window.console.log("fire event", name, params);
      let eventObject: { name: string; params?: { [x: string]: any } };
      if (params) {
        eventObject = { name, params: { ...params } };
      } else {
        eventObject = { name };
      }
      this.$store.dispatch("events/fire", eventObject);
    },
    async loadComponent() {
      if (!this.block || !this.block.type) return;

      if (this.block.type === "BlockButton" || (this.block.type === "BlockPageButtons" && this.page?.isRepeatable)) {
        return;
      }

      this.dynamicComponent = await getBlockComponent(this.block.type);
    },
  },
  computed: {
    ...eventComputed,
    ...formComputed,
    ...themesComputed,
    componentList(): object {
      return window.vm.$options.components;
    },
    errorCountText(): string {
      if (this.inputErrors === 1) {
        return "There is 1 error on the page";
      } else {
        return `There are ${this.inputErrors} errors on the page`;
      }
    },
  },
  watch: {
    theme: {
      handler(theme) {
        console.log("new theme detected", theme);
        this.$root?.["style"]?.setProperty("--bg", "red");
      },
      deep: true,
    },
  },
  async created() {
    await this.loadComponent();
  },
});
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(200px);
  opacity: 0;
}
.prevent-click {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
<template>
  <div>
    <transition name="slide-fade">
      <div v-if="showBlocks" :class="[builder ? 'prevent-click' : '']">
        <template v-if="block.type === 'BlockPageButtons' && page.isRepeatable">
          <BlockPageButtons
            :isRepeatable="page.isRepeatable"
            :currentIteration="page.currentIteration"
            :totalIterations="page.totalIterations"
            v-bind="block"
          />
        </template>
        <template v-else-if="block.type === 'BlockButton'">
          <BlockButton
            v-bind="block"
            @submit="emitEvent(eventValues.next)"
            @previous="emitEvent(eventValues.previous)"
            :isProcessing="loaders.next"
            :showPrevious="false"
          >
            <template v-slot:errors v-if="inputErrors">
              <BlockInvalidFeedback :invalidFeedback="errorCountText" :state="false"></BlockInvalidFeedback>
            </template>
          </BlockButton>
        </template>
        <template v-else>
          <component v-bind="block" v-bind.sync="block" :is="dynamicComponent" :ref="block.type" :isBuilder="builder" />
        </template>
      </div>
    </transition>
  </div>
</template>
