<script lang="ts">
import Vue, { PropOptions, VueConstructor } from "vue";

import Draggable from "vuedraggable";
import debounce from "lodash.debounce";
import { Fragment } from "vue-frag";
import { Block, Conditional, Page } from "@claimsgate/core-types";

import { ConditionalProcessor } from "@/helpers/ClaimsGate/conditionals/ConditionalProcessor";
import { PageUtility } from "@/helpers/ClaimsGate/pages";
import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
import { PageService } from "@/helpers/ClaimsGate/pages/PageService";
import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";
import BlockRender from "@/components/shared/formRender/blockRender/blockRender.vue";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import BlockButton from "@/components/shared/blocks/button.vue";
import { SlaterGordon } from "@claimsgate/core";
interface RowRenderRefs {
  $refs: {
    blockRender: [typeof BlockRender];
  };
}

export default (Vue as VueConstructor<RowRenderRefs & Vue>).extend({
  name: "RowRender",
  components: {
    Draggable,
    BlockSubtitle,
    BlockButton,
    BlockRender,
    Fragment: Fragment as any,
  },
  props: {
    blocks: {
      type: Array,
    } as PropOptions<Array<Block>>,
    claimId: {
      type: String,
      required: false,
    },
    builder: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Object,
    } as PropOptions<Page>,
    parentId: {
      type: String,
    },
  },
  data() {
    return {
      infoModalService: new InfoModalService(this.$infoModal),
      blockHover: false,
      BuilderUtility: BuilderUtility,
      pageService: new PageService(),
      blocksCopy: [] as Array<Block>,
      showBlocks: true,
      conditionalProcessor: new ConditionalProcessor(),
      funnelId: this.$route.params.funnelId,
    };
  },
  methods: {
    async showTestClaimModal() {
      await this.infoModalService.fire("info", {
        title: "This Is a Test Claim",
        text: "This claim will be marked as a test claim. Test claims do not appear in searches or statistics by default.",
      });
    },

    debouncedCalculateBlocksVisbility: debounce(
      function (page: Page) {
        //@ts-ignore
        this.calculateBlocksVisibility(page);
      },
      250,
      { leading: true }
    ),
    /** Calculates the visibility of all blocks in a given page */
    async calculateBlocksVisibility(page: Page): Promise<void> {
      for (const block of page.blocks as Array<Block>) {
        // Calculate if a given block has become visible and force
        // set the new property to ensure reactivity
        block.isVisible = await this.checkCondition(block.condition);
        await this.$nextTick();
      }
    },
    /** Checks if a condition passes */
    async checkCondition(condition: Conditional): Promise<boolean> {
      if (!condition || Object.keys(condition.actual ?? {})?.length === 0) return true;

      // Condition is not set-up as it does not refer to a variable so cannot be processed
      if (condition.actual.id === "" || !condition.actual.id) return true;

      const conditionalCopy: Conditional = JSON.parse(JSON.stringify(condition));

      const dependencyBlock = PageUtility.findVariableWithBlock(this.page, conditionalCopy.actual.id);

      // Parse the required variable
      conditionalCopy.actual = PageUtility.loadVariable(
        this.page,
        conditionalCopy.actual.id,
        this.$route.params.claimId
      );
      console.log(`x-cond page load var`, conditionalCopy.actual);
      const { result } = await this.conditionalProcessor.executeConditional(conditionalCopy);

      if (!dependencyBlock) {
        return result;
      }

      // If the dependency for this conditional is collected on the current page
      // and is not visible then the conditional will fail
      if (dependencyBlock && !dependencyBlock.isVisible) {
        return false;
      }

      return result;
    },
    emitEvent(name: string, params?: Record<string, any>) {
      window.console.log("fire event", name, params);
      let eventObject: { name: string; params?: { [x: string]: any } };
      if (params) {
        eventObject = { name, params: { ...params } };
      } else {
        eventObject = { name };
      }
      this.$store.dispatch("events/fire", eventObject);
    },
    /**
     * Assert wether the given block has a conditional
     */
    hasConditional(block) {
      if (block?.condition?.value?.actual?.id) {
        return true;
      }
      return false;
    },
    /**
     * Create a string the represent the conditional associated with the given block
     */
    conditionalExpression(block): string {
      try {
        if (block?.condition.value?.actual?.field) {
          const condition = block.condition.value;
          const actual = condition.actual;
          const type = condition.type
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase();
          let values;
          if (Array.isArray(condition?.values) && condition?.values?.length > 0) {
            values = condition.values.join(", or ");
          } else if (!Array.isArray(condition?.values) && condition.values && condition.values?.length > 0) {
            values = condition.values;
          } else {
            values = condition.value;
          }
          return `${actual.field ?? ""} ${type ?? ""} ${values ?? ""} `;
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },
    handleBlockDrag() {
      this.$emit("update:blocks", this.blocksCopy);
    },
    refreshPage() {
      console.log("running fade");
      this.showBlocks = false;
      this.$nextTick(() => {
        this.showBlocks = true;
      });
    },
    calculateColumnClass() {
      // Check if the page is set to full width
      if (this.page && this.page.isFullWidth) {
        return "col-12"; // Full width
      }
      // Original constrained width
      return "col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 tw-px-8";
    },
  },
  computed: {
    ...eventComputed,
    ...themesComputed,
    ...formComputed,
    blockAnswers(): Array<any> {
      return this.blocks.map((block) => block.answer);
    },
    blocksInErrorState(): Number {
      return this.blocks.filter((block) => block.state === false)?.length;
    },
    showPrevious(): boolean {
      if (!this.funnelMetaCache.allowPrevious) {
        return false;
      }
      if (this.page.isStart) {
        return false;
      }

      const SLATER_GORDON_PAGE_MAP = {
        thankYou: "A9xWlX5J4iaRcmkTJD9V",
        carFinanceProviderWorkflow: "ezOQ1uWn4YBMbqVOwBev",
      };
      if (
        this.funnelId === SlaterGordon.Funnels.MISSOLD_CAR_FINANCE_FUNNEL_ID &&
        (this.page?.id === SLATER_GORDON_PAGE_MAP.thankYou ||
          this.page?.id === SLATER_GORDON_PAGE_MAP.carFinanceProviderWorkflow)
      ) {
        return false;
      }

      if (this.pageHasCompletedAgreement && !this.funnelMetaCache.allowVoidAgreements) {
        return false;
      }
      return true;
    },
    pageHasCompletedAgreement(): boolean {
      return this.blocks.some((block) => block.type === "BlockAgreement" && block.answer);
    },
  },
  watch: {
    /** Watch the page for any modifications to child blocks */
    blockAnswers: {
      handler: function (): void {
        this.blocksCopy = this.blocks;
        this.debouncedCalculateBlocksVisbility({ blocks: this.blocksCopy });
      },
      deep: true,
      immediate: true,
    },
    event: {
      async handler(newVal) {
        const { name } = newVal;
        console.log("Row heard event", name, this.eventValues.refreshPage);
        if (name === this.eventValues.refreshPage) {
          this.refreshPage();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.calculateBlocksVisibility(this.page);
  },
});
</script>
<style scoped>
/* Block */
.block {
  z-index: 1099;
}

.block:hover {
  border-radius: 0.2rem;
  outline: 2px solid rgb(52, 82, 225);
  background-color: rgba(52, 81, 225, 0.295);
  transition: 0.2s;
  z-index: 12;
}
.block-buttons {
  margin-top: -2px;
  right: 6px;
  display: none;
  position: absolute;
  z-index: 13;
}
.block:hover .block-buttons {
  display: block;
}

.conditional-label {
  margin-top: -2px;
  /* left: 10px;
  display: none; */
  /* position: absolute; */
  z-index: 13;
}
</style>
<template>
  <Fragment>
    <!--<b-row class="justify-content-center" :style="[theme.fontStyles, theme.primaryStyles]" v-if="blocks">
      <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6" v-if="blocks && blocks.length > 0">
        <ProgressBar />
      </div>
    </b-row>-->
    <b-row class="justify-content-center" :style="[theme.fontStyles, theme.primaryStyles]" v-if="blocks">
      <div :class="calculateColumnClass()" v-if="blocks && blocks.length > 0">
        <!-- SGUK -->
        <template v-if="funnelId === '8WSn05dAgv6kr09gtUP3'">
          <div class="d-flex justify-content-start">
            <BlockSubtitle
              textColour="text-muted"
              align="center"
              weight="semibold"
              class="d-inline-block align-center"
              styles="h5"
              :isBuilder="false"
              :text="'Mercedes-Benz, {{model}}, {{yearOfManufacture}}, {{vehicleRegistration}}'"
            />
          </div>
          <div style="padding-top: 15px" />
        </template>

        <template v-if="!builder">
          <template v-for="(block, blockIndex) in page.blocks">
            <div v-if="page.blocks[blockIndex].isVisible || builder" :key="block.id + 'BLOCK'">
              <BlockRender
                :builder="builder"
                :block="blocks[blockIndex]"
                ref="blockRender"
                :page="page"
                :inputErrors="blocksInErrorState"
              />
            </div>
          </template>
          <template v-if="showPrevious">
            <BlockButton
              @previous="emitEvent(eventValues.previous)"
              :isPreviousProcessing="loaders.previous"
              :showPrevious="true"
            >
            </BlockButton>
          </template>
          <!-- Test Claim Banner -->
          <template v-if="$route.query.testClaim === null">
            <b-row
              ><b-col class="text-center">
                <b-button class="mt-5" @click="showTestClaimModal()">
                  <h5 class="text-center text-white my-2" style="vertical-align: middle">
                    <font-awesome-icon icon="info" class="mr-2" />This is a test claim
                  </h5>
                </b-button>
              </b-col></b-row
            >
          </template>
        </template>
        <template v-else>
          <Draggable
            :options="{ animation: 150 }"
            v-model="blocksCopy"
            :disabled="!builder"
            @change="handleBlockDrag()"
          >
            <template v-for="block in blocksCopy">
              <div
                ref="block"
                :class="builder ? 'block' : ''"
                :key="block.id"
                :id="'block' + block.id"
                @1seover="blockHover = true"
                @mouseleave="blockHover = false"
              >
                <div
                  :class="builder ? 'block-buttons' : 'd-none'"
                  :for="'block' + block.id"
                  @click="emitEvent(eventValues.toggleEditModal, { id: block.id })"
                >
                  <div
                    class="conditional-label"
                    :class="builder ? 'block-buttons' : 'd-none'"
                    :for="'block' + block.id"
                    v-if="hasConditional(block)"
                  >
                    <b-button variant="light text-dark" size="sm" class="badge px-1">
                      {{ conditionalExpression(block) }}
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      size="md"
                      class="mr-1"
                      :class="{ 'pt-3': hasConditional(block) }"
                      variant="primary text-white"
                    >
                      <i class="fas fa-pen mx-1" />
                      Edit
                    </b-button>
                  </div>
                </div>
                <template>
                  <BlockRender
                    :builder="builder"
                    :block="BuilderUtility.parseBlock(JSON.parse(JSON.stringify(block)), true)"
                    ref="blockRender"
                    :page="page"
                    :inputErrors="blocksInErrorState"
                  />
                </template>
              </div>
            </template>
          </Draggable>

          <template>
            <div class="text-center pt-4">
              <b-button
                size="lg"
                @click="emitEvent(eventValues.toggleAddBlockModal, { id: parentId })"
                variant="light"
                class="text-dark mr-1"
              >
                <i class="fas fa-plus mr-1" />
                Add block
              </b-button>
            </div>
          </template>
        </template>
      </div>
    </b-row>
  </Fragment>
</template>
