// Services
import { SentryService } from "../../../../helpers/ClaimsGate/SentryService";

// Types
import { InfoModalType, InfoModalParams } from "@claimsgate/core-types";
class InfoModalError extends Error {
  constructor(message, name) {
    super(message);
    this.name = name;
  }
}

class InfoModalService {
  /** @type { InformationModal } */
  /** Instance of the InformationModal to be passed from a vue component */
  infoModal;
  /** @type { SentryService } */
  sentryService;
  /** Default params to be passed to the InformationModal */
  configs;

  constructor(infoModal) {
    this.infoModal = infoModal;
    this.sentryService = new SentryService();

    this.configs = {
      warning: {
        icon: "warning",
        title: "Are You Sure?",
        text: "",
        cancelButtonText: "No. Go back",
        confirmButtonText: "Yes, Continue!",
      } as InfoModalParams,
      info: {
        icon: "info",
        title: "",
        text: "",
        dismissButtonText: "Understood",
      } as InfoModalParams,
      error: {
        icon: "error",
        title: "Unexpected Error",
        text: "Whoops, we're not sure what went wrong. Our team has been notified, please try again later!",
        dismissButtonText: "Understood",
      } as InfoModalParams,
      delete: {
        icon: "delete",
        title: "Delete",
        text: "",
        cancelButtonText: "No. Go back",
        confirmButtonText: "Yes, Delete!",
      } as InfoModalParams,
      success: {
        icon: "success",
        title: "Success",
        text: "",
        dismissButtonText: "Understood",
      } as InfoModalParams,
    };
  }

  /**
   * Constrcuts a params object to be passed to InformationModal
   * Also fires a Sentry Error if requested
   */
  fire(
    type: InfoModalType = "error",
    params: InfoModalParams = {},
    sentryConfig: import("@/types/vue/SentryConfig").SentryConfig = { preventFire: false, route: undefined }
  ) {
    // replace the defaults specified in config with the value passe
    const infoModalConfig = this.configs[type];
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).map((key) => {
        infoModalConfig[key] = params[key];
      });
    }

    // send an error log to Sentry
    if (type === "error" && sentryConfig.preventFire !== true) {
      this.sentryService.captureException(
        "Swal Error",
        new InfoModalError(infoModalConfig.text, infoModalConfig.title),
        sentryConfig.route
      );
    }

    // construct
    const modalParams: InfoModalParams = {
      type: type,
      ...infoModalConfig,
    };

    return new Promise((resolve, reject) => {
      this.infoModal.show(modalParams, resolve, reject);
    });
  }
}

export { InfoModalService };
