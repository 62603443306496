<script lang="ts">
const fireVal = "events/fire";

// Import Vuex Getters
import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
// Import UI elements
import Vue, { VueConstructor } from "vue";
import RowRender from "@/components/shared/formRender/rowRender/rowRender.vue";
import { Container, Page } from "@claimsgate/core-types";

import { PropType } from "vue";

interface PageRenderRefs {
  $refs: {
    rowRender: [typeof RowRender];
  };
}
export default (Vue as VueConstructor<PageRenderRefs & Vue>).extend({
  name: "PageRender",
  props: {
    page: {
      type: Object as PropType<Page>,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      BuilderUtility: BuilderUtility,
      rowHovers: [false],
      colMap: {
        xs: { sm: "3", md: "2", lg: "2", xl: "2" },
        sm: { sm: "6", md: "3", lg: "3", xl: "3" },
        md: { sm: "12", md: "4", lg: "4", xl: "4" },
        lg: { sm: "12", md: "6", lg: "6", xl: "6" },
        xl: { sm: "12", md: "9", lg: "9", xl: "9" },
        full: { sm: "12", md: "12", lg: "12", xl: "12" },
      },
      blocksCopy: [] as Array<Container>,
      containers: {} as Container,
    };
  },
  methods: {
    emitEvent(name: string, params: any) {
      window.console.log("called emit event with", name, params);
      const eventObject = { name: name, params: { ...params } };
      this.$store.dispatch(fireVal, eventObject);
    },
    emitBlocks(event) {
      window.console.log("event is: ", event);

      window.console.log("blocks is now: ", JSON.parse(JSON.stringify(this.blocksCopy)));
      this.$emit("update:blocks", this.blocksCopy);
    },

    calculateContainerSize() {
      console.log("calculateContainerSize: ", this.page);
      // Check if the page is set to full width
      if (this.page && this.page.isFullWidth) {
        console.log("calculateContainerSize: full width");
        return "col-12"; // Full width
      }
      // Original constrained width
      return "col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6";
    },
    setHover(containerIndex, value) {
      if (containerIndex != 0) {
        this.$set(this.rowHovers, containerIndex, value);
      }
    },
  },
  computed: {
    ...eventComputed,
    ...formComputed,
    ...themesComputed,
  },
  watch: {
    blocks: {
      /**
       * Watches for new blocks
       */
      handler(newBlocks) {
        this.blocksCopy = newBlocks;
      },
    },
    theme: {
      handler(theme) {
        console.log("new theme detected", theme);
        this.$root?.["style"]?.setProperty("--bg", "red");
      },
      deep: true,
    },
  },
});
</script>

<template>
  <div ref="contentRef" class="tw-w-full" style="padding-right: 0px; padding-left: 0px">
    <div v-bind:style="[theme.fontStyles, theme.primaryStyles]" class="tw-w-full">
      <slot></slot>
    </div>
  </div>
</template>
