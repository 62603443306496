<script>
export default {
  methods: {
    redirectToClaimsGate() {
      window.open("https://get.claimsgate.co.uk/", "_blank");
    },
  },
};
</script>
<style scoped>
.btn-custom {
  min-width: 325px;
  border: none;

  background-image: linear-gradient(to right, #0f131d, #232e41, #015fdf, #4455bb);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);

  cursor: pointer;
  background-size: 300% 100%;

  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-custom:hover {
  transform: scale(1.025);
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
</style>
<template>
  <div class="d-flex justify-content-center align-items-center">
    <b-button size="lg" class="btn-custom mx-2 p-3 d-block" @click="redirectToClaimsGate()">
      <span class="mr-3 d-inline">⚡ Onboarding by</span>
      <b-img class="d-inline" :src="require('@/assets/images/logo-light-short.png')" height="42px"></b-img>
    </b-button>
  </div>
</template>
