<script lang="ts">
// Vue
import Vue from "vue";

// COmponents
import HorizontalTopbar from "@/components/shared/layout/horizontal-topbar.vue";
import LoadingBar from "@/components/shared/layout/loadingBar.vue";
import Footer from "@/components/shared/layout/footer.vue";

// Vue X
import { layoutComputed, permissionsComputed, navigationComputed, themesComputed } from "@/state/helpers";

// Functions
import { onWorkspaceBranded } from "@/helpers/ClaimsGate/RedirectHelper";

/**
 * Horizontal-layout
 */
export default Vue.extend({
  name: "HorizontalLayout",
  props: {
    builder: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HorizontalTopbar,

    LoadingBar,
    Footer,
  },
  data() {
    return {
      configs: undefined,
      height: 0,
    };
  },
  computed: {
    ...layoutComputed,
    ...permissionsComputed,
    ...navigationComputed,
    ...themesComputed,

    onWorkspaceBrandedPage: function (): boolean {
      return onWorkspaceBranded(this.$route);
    },

    // ! Quick Solution: Display Trustpilot PNG on Milberg Funnel Only
    onMilbergFunnel(): boolean {
      // portal.milbergclaims.co.uk
      if (window.location.host === "portal.milbergclaims.co.uk") {
        return true;
      }
      return false;
    },
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-layout-size");
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  async beforeMount() {},
  mounted() {},
});
</script>
<template>
  <div id="layout-wrapper" class="tw-flex tw-flex-col tw-min-h-screen tw-overflow-x-hidden">
    <!-- Sticky header -->
    <HorizontalTopbar :type="topbar" :width="layoutWidth" :loading="loading" />

    <!-- Main content area with conditional padding -->
    <div
      class="tw-flex-grow tw-flex tw-flex-col tw-min-h-screen tw-w-full tw-overflow-x-hidden"
      :class="{ 'tw-pt-8': !fullWidth }"
      :style="theme.backgroundStyle"
    >
      <!-- Blocks slot -->
      <slot name="blocks" />

      <template v-if="loading">
        <div class="d-flex align-items-center justify-content-center tw-flex-grow" ref="contentRef">
          <LoadingBar :loading="loading" />
        </div>
      </template>

      <template v-else>
        <!-- Main content using width utilities directly -->
        <div class="tw-flex-grow tw-flex tw-flex-col tw-w-full tw-overflow-x-hidden" ref="contentRef">
          <div
            :class="fullWidth ? 'tw-w-full' : 'container-fluid'"
            :style="fullWidth ? 'padding: 0; margin: 0;' : ''"
            class="tw-flex-grow"
          >
            <slot />
          </div>
        </div>
      </template>
    </div>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<style>
/* Let the header take its natural height based on content */
#page-topbar {
  /* Using better shadow via Bootstrap utility instead of custom CSS */
  box-shadow: 0 0 1rem 0px rgba(0, 0, 0, 0.2) !important;
}
</style>
