<script lang="ts">
// General
import Vue, { VueConstructor } from "vue";

import { authComputed, layoutComputed, permissionsComputed, navigationComputed, themesComputed } from "@/state/helpers";
import { getFirebaseBackend } from "@/authUtils.js";

// Functions
import { getClaimsGateDomain, onWorkspaceBranded } from "@/helpers/ClaimsGate/RedirectHelper";
import { Mishcon } from "@claimsgate/core";

/** Interface to define additional component properties **/
interface HorizontalTopbar extends Vue {}

/** Definition for a Navigation Link used in HorizontalTopbar */
type NavigationLink = {
  newTab?: boolean; // indicates whether the route should open in a new tab
  name: string;
  route: string;
  params?: Record<string, string>;
  query?: Record<string, string>;

  icon?: string;
};

export default (Vue as VueConstructor<Vue & HorizontalTopbar>).extend({
  name: "HorizontalTopbar",
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...authComputed,
    ...layoutComputed,
    ...permissionsComputed,
    ...navigationComputed,
    ...themesComputed,
    onSlaterFunnel(): boolean {
      return this.$route.path.includes("zMDIrkLdtgtCLfLxjlAW");
    },

    onComplexFunnel(): boolean {
      return window.location.host.includes("complex") || window.location.pathname.includes("OZOWEacTGghhgdLI24i0");
    },

    showCountrySelector(): boolean {
      return (
        window.location.host.includes("onecoin") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("preview")
      );
    },

    userEmail: function (): string {
      return this.userDocument?.email ?? "";
    },

    userPhoneNumber: function (): string {
      return this.userDocument?.phoneNumber ?? "";
    },

    /** Returns a boolean indicating whether or not the page we are on is a workspace branded page */
    onWorkspaceBrandedPage: function (): boolean {
      return onWorkspaceBranded(this.$route);
    },

    /** Returns the list of Navigation Links */
    navigationLinks: function (): Array<NavigationLink> | null {
      let navigationArray: Array<NavigationLink> | null = [];

      // Workspace navigation
      if (this.userWorkspaces?.length !== 0) {
        navigationArray.push({
          name: "Workspace",
          route: this.workspaceSelectedWorkspace ? "Workspace" : "WorkspaceSelect",
          params: this.workspaceSelectedWorkspace ? { workspaceId: this.workspaceSelectedWorkspace } : {},
        });
      }

      // Home navigation (leads to WorkspaceClaims if on workspace branded page)
      navigationArray.push({
        name: this.$t("navigation.myClaims") as string,
        route:
          getClaimsGateDomain() !== window.location.host && this.homeSelectedWorkspace ? "WorkspaceClaims" : "Claims",
        params:
          getClaimsGateDomain() !== window.location.host && this.homeSelectedWorkspace
            ? { workspaceId: this.homeSelectedWorkspace }
            : {},
      });

      return navigationArray;
    },

    /** Returns the list of Navigation Links for the Me dropdown */
    meLinks: function (): Array<NavigationLink> {
      return [
        {
          name: this.$t("navigation.menu.myDetails"),
          route: "Me",
          params:
            this.onWorkspaceBrandedPage || this.$route.name === "legal"
              ? { workspaceId: this.navigationWorkspaceId }
              : {},
          icon: "user-circle",
        },
        {
          name: this.$t("navigation.menu.settings"),
          route: "Settings",
          params:
            this.onWorkspaceBrandedPage || this.$route.name === "legal"
              ? { workspaceId: this.navigationWorkspaceId }
              : {},
          icon: "cog",
        },
        {
          newTab: true,
          name: this.$t("navigation.menu.legal"),
          route: "legal",
          params: { document: "privacy" },
          icon: "book",
        },
        {
          name: this.$t("navigation.menu.logOut"),
          route: "logout",
          params: this.onWorkspaceBrandedPage ? { workspaceId: this.navigationWorkspaceId } : {},
          icon: "door-open",
        },
      ] as Array<NavigationLink>;
    },

    cssVars(): { "--background-color": string } {
      return { "--background-color": this.theme.headerFooterColour ? this.theme.headerFooterColour : "white" };
    },

    shouldShowHeader(): boolean {
      console.log(">>> CALLING", this.onWorkspaceBrandedPage, this.$route, onWorkspaceBranded(this.$route));
      if (onWorkspaceBranded(this.$route)) {
        // Only show when we have ALL workspace branding elements
        return !!(
          this.theme.headerFooterColour &&
          this.theme.headerFooterTextColour &&
          (this.navigationLogo || this.navigationWorkspaceName)
        );
      }
      // On Claims Gate pages, show default header
      return true;
    },
  },
  data() {
    return {
      bulkUpload: false,
      userData: getFirebaseBackend().getUserData(),
      selectedCountry: localStorage.getItem("locale") || this.$i18n.locale,

      text: null,
      flag: null,
      value: null,
    };
  },

  mounted() {
    if (this.$route.query.testIntl !== undefined) {
      localStorage.setItem("testIntl", "true");
    }

    if (localStorage.getItem("locale") !== null) {
      this.changeBodyDirection();
    }
  },

  methods: {
    changeLocale() {
      this.$store.dispatch("themes/setLanguage", this.selectedCountry);
      this.$i18n.locale = this.selectedCountry;
      localStorage.setItem("locale", this.selectedCountry);
      this.changeBodyDirection();
    },

    changeBodyDirection() {
      if (this.selectedCountry === "ar") {
        document.body.setAttribute("dir", "rtl");
      } else {
        document.body.setAttribute("dir", "ltr");
      }
    },

    homeRoute(): void {
      if (window.location.host.includes("onecoin")) {
        window.open("https://www.onecoinvictims.com/", "_blank");
        return;
      }
      this.$router.push({
        name:
          getClaimsGateDomain() !== window.location.host && this.homeSelectedWorkspace ? "WorkspaceClaims" : "Claims",
        params:
          getClaimsGateDomain() !== window.location.host && this.homeSelectedWorkspace
            ? { workspaceId: this.homeSelectedWorkspace }
            : {},
      });
    },

    /** Opens a route in a new tab on claimsgate domain (dependent on environment) **/
    openInNewTab(routeName, routeParams, routeQueryParams): void {
      if (routeName === "legal" && window.location.host.includes("onecoin")) {
        routeQueryParams = { queryWorkspaceId: Mishcon.WORKSPACE_ID };
      }

      let routeData = this.$router.resolve({ name: routeName, params: routeParams, query: routeQueryParams });

      // ? this logic is added here as Vetur complains if I move it into a seperate function
      let url;
      if (window.location.host === "localhost") {
        window.open(routeData.href, "_blank");
        return;
      } else if (process.env.VUE_APP_PROJECTId === "dev-claims-cake") {
        url = "https://dev-claims-cake.web.app";
      } else if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
        url = "https://claimsgate.co.uk";
      } else {
        url = "https://claimsgate.co.uk";
      }

      window.open(`${url}${routeData.href}`, "_blank");
    },

    toggleMenu(): void {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
  },
  watch: {
    language: {
      handler(newVal, oldVal) {
        console.log(">>> selectedCountry changed", newVal, oldVal);
        if (newVal !== oldVal) {
          this.selectedCountry = newVal;

          this.changeLocale();
        }
      },
    },

    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
});
</script>
<style>
.dropdown-menu {
  background-color: var(--background-color);
  opacity: 1;
}
.dropdown-item:hover {
  background-color: var(--background-color);
}
</style>
<style scoped>
.hover-shift-right:hover {
  transition: all 0.4s;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform: translateX(10px);
}
.hover-shift-right {
  transition: all 0.4s;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.logo-header {
  max-height: 65px;
  max-width: 200px;
  width: auto;
  height: auto;
}

#page-topbar {
  box-shadow: none !important;
}
</style>
<template>
  <div>
    <div>
      <div v-if="shouldShowHeader">
        <header
          id="page-topbar"
          class="tw-sticky tw-top-0 tw-z-50 tw-shadow-none"
          :style="{ 'background-color': theme.headerFooterColour }"
        >
          <div class="navbar-header">
            <!-- Logo -->
            <div class="d-flex">
              <div class="navbar-brand-box">
                <a role="button" class="logo logo-light">
                  <template v-if="onWorkspaceBrandedPage && navigationLogo">
                    <img
                      :src="navigationLogo"
                      :class="onComplexFunnel ? 'text-white logo-header' : 'text-black logo-header'"
                      :style="!onComplexFunnel ? 'height: 42px' : ''"
                      :alt="navigationWorkspaceName"
                    />
                  </template>
                  <template v-else-if="onWorkspaceBrandedPage && navigationWorkspaceName">
                    <b-button variant="link" class="mr-2">
                      <h5 class="mt-1" :style="{ color: theme.headerFooterTextColour }">
                        {{ navigationWorkspaceName }}
                      </h5>
                    </b-button>
                  </template>
                  <template v-else>
                    <img :src="require('@/assets/images/logo-dark.png')" class="text-white" style="height: 38px" alt />
                  </template>
                </a>
              </div>

              <!-- <template v-if="showCountrySelector">
          <select v-model="selectedCountry" @change="changeLocale" style="max-width: 75px; margin-left: 10px">
            <option value="en">English</option>
            <option value="ar">Arabic</option>
            <option value="hi">Hindi</option>
            <option value="ml">Malay</option>
            <option value="ms">ID Malay</option>
          </select>
        </template> -->
            </div>

            <!-- Render Navigation Links available to the User -->
            <template v-if="loggedIn">
              <!-- Big Screen Render (assuming 150 pixels per menu item)-->
              <div class="d-none d-md-block">
                <template v-if="!navigationHideTrackPageClaimant">
                  <b-button
                    v-for="link in navigationLinks"
                    :key="link.name"
                    variant="link"
                    class="mr-2"
                    @click="$router.push({ name: link.route, params: link.params })"
                  >
                    <h5 class="mt-1" :style="{ color: theme.headerFooterTextColour }">
                      {{ link.name }}
                    </h5>
                  </b-button>
                </template>
                <template v-if="(userEmail || userPhoneNumber) && !navigationHideTrackPageClaimant">
                  <b-dropdown right :lazy="true" ref="meDropdown" class="mr-2" variant="link" no-caret :style="cssVars">
                    <template #button-content>
                      <b-button variant="link" style="margin: 0px; padding: 0px">
                        <h5 class="mt-1" :style="{ color: theme.headerFooterTextColour }">
                          <font-awesome-icon icon="bars" class="mr-2" /> {{ $t("navigation.me") }}
                        </h5>
                      </b-button>
                    </template>
                    <template>
                      <b-dropdown-item
                        v-for="link in meLinks"
                        :key="link.name"
                        @click="
                          link.newTab
                            ? openInNewTab(link.route, link.params, link?.query)
                            : $router.push({ name: link.route, params: link.params })
                        "
                        v-bind:style="[theme.headerFooterStyles]"
                      >
                        <h5 class="mt-1 hover-shift-right" v-bind:style="[theme.headerFooterTextStyles]">
                          <font-awesome-icon :icon="link.icon" class="mr-2" />
                          <span>{{ link.name }}</span>
                        </h5>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </template>
                <template v-else-if="(userEmail || userPhoneNumber) && navigationHideTrackPageClaimant">
                  <b-dropdown right :lazy="true" ref="meDropdown" class="mr-2" variant="link" no-caret :style="cssVars">
                    <template #button-content>
                      <b-button variant="link" style="margin: 0px; padding: 0px">
                        <h5 class="mt-1" :style="{ color: theme.headerFooterTextColour }">
                          <font-awesome-icon icon="bars" class="mr-2" /> {{ $t("navigation.me") }}
                        </h5>
                      </b-button>
                    </template>
                    <b-dropdown-item
                      :key="'logout'"
                      @click="
                        $router.push({
                          name: 'logout',
                        })
                      "
                    >
                      <h4 class="mt-1" v-bind:style="[theme.headerFooterTextStyles]">
                        <font-awesome-icon icon="angle-right" class="ml-1 mr-2" />
                        <span>Logout</span>
                      </h4>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template v-else>
                  <b-button
                    variant="link"
                    class="mr-2"
                    @click="
                      $router.push({
                        name: 'login',
                        params: onWorkspaceBrandedPage ? { workspaceId: navigationWorkspaceId } : {},
                      })
                    "
                  >
                    <h5 class="mt-1" :style="{ color: theme.headerFooterTextColour }">Login</h5>
                  </b-button>
                </template>
              </div>

              <!-- Small Screen Render -->
              <div class="d-md-none">
                <b-dropdown id="dropdown-1" class="btn" block variant="transparent" :style="cssVars">
                  <template #button-content>
                    <h4 class="mt-1" :style="{ color: theme.headerFooterTextColour }">
                      <font-awesome-icon icon="bars" class="mr-2" />
                      <span class="mr-2">Menu</span>
                    </h4>
                  </template>

                  <template v-if="!navigationHideTrackPageClaimant">
                    <b-dropdown-item
                      v-for="link in navigationLinks"
                      :key="link.name"
                      @click="$router.push({ name: link.route, params: link.params })"
                    >
                      <h4 class="mt-1" v-bind:style="[theme.headerFooterTextStyles]">
                        <font-awesome-icon icon="angle-right" class="ml-1 mr-2" />
                        <span>{{ link.name }}</span>
                      </h4>
                    </b-dropdown-item>
                  </template>
                  <div class="dropdown-divider"></div>

                  <template v-if="(userEmail || userPhoneNumber) && !navigationHideTrackPageClaimant">
                    <template>
                      <b-dropdown-item
                        v-for="link in meLinks"
                        :key="link.name"
                        @click="
                          link.newTab
                            ? openInNewTab(link.route, link.params)
                            : $router.push({ name: link.route, params: link.params })
                        "
                      >
                        <h4 class="mt-1" v-bind:style="[theme.headerFooterTextStyles]">
                          <font-awesome-icon :icon="link.icon" class="mr-2" />
                          <span>{{ link.name }}</span>
                        </h4>
                      </b-dropdown-item>
                    </template>
                  </template>

                  <template v-else-if="userEmail || (userPhoneNumber && navigationHideTrackPageClaimant)">
                    <b-dropdown-item
                      @click="
                        $router.push({
                          name: 'logout',
                        })
                      "
                    >
                      <h4 class="mt-1" v-bind:style="[theme.headerFooterTextStyles]">
                        <font-awesome-icon icon="angle-right" class="ml-1 mr-2" />
                        <span>Logout</span>
                      </h4>
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      @click="
                        $router.push({
                          name: 'login',
                          params: onWorkspaceBrandedPage ? { workspaceId: navigationWorkspaceId } : {},
                        })
                      "
                    >
                      <h4 class="mt-1" v-bind:style="[theme.headerFooterTextStyles]">
                        <font-awesome-icon icon="angle-right" class="ml-1 mr-2" />
                        <span>Login</span>
                      </h4>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </template>
          </div>
        </header>
        <section class="tw-py-4 lg:tw-py-4 tw-bg-[#CDEDFE]" v-if="onSlaterFunnel">
          <div class="tw-container tw-mx-auto">
            <div class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-sm-regular lg:tw-text-lg">
              <!-- Ultra-compact mobile version -->
              <span class="tw-underline-offset-4 tw-font-semibold tw-text-[#0086C9] tw-mt-[2px]"
                >Join 100,000 Drivers - No Win, No Fee - Rated
                <span class="tw-underline tw-font-bold">Excellent</span></span
              >
            </div>
          </div>
        </section>

        <section class="tw-py-4 lg:tw-py-4 tw-bg-[#BCDEE1]" v-if="onComplexFunnel">
          <div class="tw-container tw-mx-auto">
            <div class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-sm-regular lg:tw-text-lg">
              <!-- Ultra-compact mobile version -->
              <span class="tw-underline-offset-4 tw-font-semibold tw-text-[#2A5A5F] tw-mt-[2px]"
                >100,000+ Helped • No Win, No Fee • Rated <span class="tw-underline tw-font-bold">Excellent</span></span
              >
            </div>
          </div>
        </section>
      </div>

      <!-- Loading placeholder with subtle animation -->
      <div v-else>
        <header class="tw-sticky tw-top-0 tw-z-50 tw-h-16 tw-bg-white">
          <div class="navbar-header tw-flex tw-items-center tw-justify-between tw-h-full">
            <!-- Logo placeholder -->
            <div class="tw-flex tw-items-center">
              <div class="navbar-brand-box">
                <div
                  class="tw-w-[200px] tw-h-[38px] tw-bg-gray-200 tw-rounded tw-animate-[pulse_2s_ease-in-out_infinite]"
                ></div>
              </div>
            </div>

            <!-- Navigation items placeholder -->
            <div class="tw-hidden md:tw-flex tw-items-center tw-gap-2">
              <div
                class="tw-w-24 tw-h-6 tw-bg-gray-200 tw-rounded tw-animate-[pulse_2s_ease-in-out_infinite] tw-delay-100"
              ></div>
              <div
                class="tw-w-24 tw-h-6 tw-bg-gray-200 tw-rounded tw-animate-[pulse_2s_ease-in-out_infinite] tw-delay-200"
              ></div>
              <div
                class="tw-w-24 tw-h-6 tw-bg-gray-200 tw-rounded tw-animate-[pulse_2s_ease-in-out_infinite] tw-delay-300"
              ></div>
            </div>

            <!-- Mobile menu placeholder -->
            <div class="md:tw-hidden">
              <div class="tw-w-8 tw-h-8 tw-bg-gray-200 tw-rounded tw-animate-[pulse_2s_ease-in-out_infinite]"></div>
            </div>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>
