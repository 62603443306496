<script lang="ts">
// Vue
import Vue from "vue";

// Components
import AffiliateBadge from "@/components/shared/layout/affiliatebadge.vue";

// Vue X
import { layoutComputed, permissionsComputed, navigationComputed, themesComputed } from "@/state/helpers";

// Functions
import { onWorkspaceBranded } from "@/helpers/ClaimsGate/RedirectHelper";
import TitleBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/TitleBlock.vue";

//imports for complex law
import AmIEligible from "@/components/shared/blocks/complexLawPcpLandingPage/components/AmIEligible.vue";
import FAQ from "@/components/shared/blocks/complexLawPcpLandingPage/components/FAQ.vue";
import HowItWorks from "@/components/shared/blocks/complexLawPcpLandingPage/components/HowItWorks.vue";
/**
 * Horizontal-layout
 */
export default Vue.extend({
  name: "Footer",
  props: {},
  components: {
    AffiliateBadge,
    TitleBlock,
    AmIEligible,
    FAQ,
    HowItWorks,
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
    ...permissionsComputed,
    ...navigationComputed,
    ...themesComputed,

    onComplexFunnelFirstPage(): boolean {
      if (this.$route.path.includes("OZOWEacTGghhgdLI24i0") && this.$route.path.includes("eHOlwrhi6sr7gK1OJJ8t")) {
        return true;
      }
      return false;
    },

    onSlaterFunnelFirstPage(): boolean {
      if (this.$route.path.includes("zMDIrkLdtgtCLfLxjlAW") && this.$route.path.includes("GrH6ZXqT4y0Fs72C2U4n")) {
        return true;
      }
      return false;
    },

    workspaceSelectedId(): string {
      return this.$store.getters["navigation/getWorkspaceId"];
    },

    onClaimsGateWebsite(): boolean {
      if (window.location.host.includes("claimsgate") || window.location.host.includes("localhost")) {
        return true;
      }
      return false;
    },

    onWorkspaceBrandedPage: function (): boolean {
      return onWorkspaceBranded(this.$route);
    },

    onMilbergFunnel(): boolean {
      // portal.milbergclaims.co.uk
      if (window.location.host === "portal.milbergclaims.co.uk") {
        return true;
      }
      return false;
    },

    onComplexFunnel(): boolean {
      if (
        window.location.host.includes("complexclaims.co.uk") ||
        window.location.pathname.includes("OZOWEacTGghhgdLI24i0") ||
        this.workspaceSelectedId === "kEL2nSiPu0l0grSQwxmo"
      ) {
        return true;
      }
      return false;
    },

    onSlaterFunnel(): boolean {
      if (
        window.location.host.includes("slatergordonuk.co.uk") ||
        window.location.pathname.includes("zMDIrkLdtgtCLfLxjlAW") ||
        this.workspaceSelectedId === "qsjhb565tToX9ZsPmwJd"
      ) {
        return true;
      }
      return false;
    },

    onFinanceFunnel(): boolean {
      if (window.location.host.includes("thefinanceclaim")) {
        return true;
      }
      return false;
    },

    onLLHRFunnel(): boolean {
      // portal.llhrclaims.com
      if (window.location.host === "portal.llhrclaims.com") {
        return true;
      }
      return false;
    },
  },
  created: () => {},
  methods: {},
  mounted() {},
});
</script>
<template>
  <!-- End Page-content -->
  <div>
    <template v-if="onComplexFunnel">
      <template v-if="!onComplexFunnelFirstPage">
        <div class="tw-mt-[64px]">
          <AmIEligible :buttonExists="false" />
          <HowItWorks :buttonExists="false" />
          <FAQ :buttonExists="false" />
        </div>
      </template>
      <div
        class="tw-flex tw-flex-col tw-gap-16 tw-w-full tw-py-8 tw-px-4 lg:tw-py-16 lg:tw-px-32 tw-font-sans tw-bg-[#1b4753]"
      >
        <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1440px] tw-mx-auto lg:tw-px-32 tw-w-full tw-px-2">
          <!-- Top Stack -->
          <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-justify-between tw-gap-8 tw-mb-8">
            <div class="tw-flex tw-flex-col tw-gap-8">
              <img
                src="https://cdn.claimsgate.co.uk/complexLawLandingPage/complexlaw.png"
                alt="Complex Law Logo"
                loading="lazy"
                class="tw-w-[227.8px] tw-h-[auto]"
              />
              <div class="tw-text-[#4a7c85] tw-text-md-regular tw-font-sans">
                Complex Law LTD T/A Complex Law, SJ Law, Smith Jones Solicitors and Finance Claims <br />
                Company Registration: 6938570 <br />
                <br />
                Registered Office: Avenue HQ, Mann Island,Liverpool, L3 1BP <br />
                Authorised and regulated by the Solicitors Regulation Authority.
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-w-[250px] tw-gap-3">
              <div class="tw-text-[#68B4BC] tw-text-lg-semibold tw-font-sans">
                <b>Legal</b>
              </div>
              <div>
                <a
                  href="https://www.complexlaw.co.uk/complaints-procedure"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#68B4BC]"
                  style="text-decoration: none"
                  >Complaints Procedure</a
                >
              </div>
              <div>
                <a
                  href="https://www.complexlaw.co.uk/privacy-cookies"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#68B4BC]"
                  style="text-decoration: none"
                  >Privacy and Cookies</a
                >
              </div>
              <div>
                <a
                  href="https://www.complexlaw.co.uk/terms-conditions"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#68B4BC]"
                  style="text-decoration: none"
                  >Terms and Conditions</a
                >
              </div>
            </div>
          </div>

          <!-- Bottom Stack -->
          <div class="tw-flex tw-items-start tw-pt-8" style="border-top: 1px solid #4a7c85">
            <p class="tw-text-[#4a7c85] tw-text-md-regular tw-font-sans">
              © 2025 ComplexLaw. All rights reserved | SRA Registration: 515276 | VAT Reg number: GB 976 4660 72.
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="onSlaterFunnel">
      <div
        :class="onSlaterFunnelFirstPage ? '' : 'tw-mt-[64px]'"
        v-if="!onSlaterFunnelFirstPage"
        class="tw-flex tw-flex-col tw-gap-16 tw-w-full tw-py-8 tw-px-4 lg:tw-py-16 lg:tw-px-32 tw-font-sans tw-bg-[#b9e6fe]"
      >
        <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
          <TitleBlock
            title="Get in Touch"
            description="If you would like to contact Slater & Gordon Lawyers, feel free to use the details below or reach out to us directly here:"
          />
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-justify-between lg:tw-gap-6 tw-gap-6">
            <div
              class="tw-flex tw-flex-col tw-w-full lg:tw-w-[48%] tw-p-6 tw-rounded-3xl tw-shadow-lg tw-gap-4"
              style="background-color: #e0f2fe"
            >
              <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-gap-4 lg:tw-gap-6">
                <img
                  loading="lazy"
                  src="https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Phone_Icon.png"
                  alt="Contact Us"
                  class="tw-w-16 tw-h-16 lg:tw-w-20 lg:tw-h-20 tw-object-contain"
                />

                <p class="tw-text-lg tw-font-medium" style="color: #2b536f">0330 107 6472</p>
              </div>
              <h2 class="tw-text-lg tw-font-semibold tw-text-gray-800 tw-text-center">
                Got questions? Give us a call – we're here to help!
              </h2>
            </div>

            <div
              class="tw-flex tw-flex-col tw-w-full lg:tw-w-[48%] tw-p-6 tw-rounded-3xl tw-shadow-lg tw-gap-4"
              style="background-color: #f0f9ff"
            >
              <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-gap-4 lg:tw-gap-6">
                <img
                  loading="lazy"
                  src="https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Letter_Icon.png"
                  alt="Contact Us"
                  class="tw-w-16 tw-h-16 lg:tw-w-20 lg:tw-h-20 tw-object-contain"
                />
                <p class="tw-text-sm lg:tw-text-lg tw-font-medium tw-text-center" style="color: #2b536f">
                  carfinanceclaims@slatergordon.co.uk
                </p>
              </div>
              <h2 class="tw-text-lg tw-font-semibold tw-text-gray-800 tw-text-center">
                Have a question? Reach out to us – we'll get back to you soon!
              </h2>
            </div>
          </div>
          <div
            class="tw-flex lg:tw-flex-row tw-flex-col tw-w-full tw-p-16 tw-items-center lg:tw-justify-between tw-gap-12"
          >
            <img
              src="https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/SSLSGUK.png"
              alt="Slater and Gordon Logo"
              loading="lazy"
              class="tw-w-[179px] tw-h-[auto]"
            />
            <img
              src="https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/darkSGUKlogo.png"
              alt="Slater and Gordon Logo"
              loading="lazy"
              class="tw-w-[202.4px] tw-h-[auto]"
            />
            <img
              src="https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/SGUKrtrustpilot.png"
              alt="Slater and Gordon Logo"
              loading="lazy"
              class="lg:tw-w-[264px] tw-w-[202.4px] tw-h-[auto]"
            />
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-gap-16 tw-w-full tw-py-8 tw-px-4 lg:tw-py-16 lg:tw-px-32 tw-font-sans tw-bg-[#2B536F]"
      >
        <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1440px] tw-mx-auto lg:tw-px-32 tw-w-full tw-px-2">
          <!-- Top Stack -->
          <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-justify-between tw-gap-8 tw-mb-8">
            <div class="tw-flex tw-flex-col tw-gap-8">
              <img
                src="https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/slatergordonlog.png"
                alt="slatergordonuk Law Logo"
                loading="lazy"
                class="tw-w-[109px] tw-h-[auto]"
              />
              <div class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans">
                Slater and Gordon UK Limited is a company registered in England and Wales with registration number
                07931918, VAT 125 446 327 and registered office First Floor, Lee House, 90 Great Bridgewater Street,
                Manchester M1 5JW. We are an approved Alternative Business Structure authorised and regulated by the
                Solicitors Regulation Authority (SRA number: 591058) and authorised and regulated by the Financial
                Conduct Authority for insurance mediation activity (FCA number: 579176)
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-w-[250px] tw-gap-3 tw-flex-shrink-0">
              <div class="tw-text-[#D9D9D9] tw-text-lg-semibold tw-font-sans">
                <b>Legal</b>
              </div>
              <div>
                <a
                  href="https://www.slatergordon.co.uk/complaints-procedure/"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#5BCCFA]"
                  style="text-decoration: none"
                  >Complaints</a
                >
              </div>
              <div>
                <a
                  href="https://www.slatergordon.co.uk/about-us/business-policies/terms-and-conditions-of-website-use/"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#5BCCFA]"
                  style="text-decoration: none"
                  >Terms and Conditions</a
                >
              </div>
              <div>
                <a
                  href="https://www.slatergordon.co.uk/about-us/business-policies/cookie-policy/"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#5BCCFA]"
                  style="text-decoration: none"
                  >Cookie Policy</a
                >
              </div>
              <div>
                <a
                  href="https://www.slatergordon.co.uk/about-us/business-policies/privacy-policy/"
                  target="_blank"
                  class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans hover:tw-text-[#5BCCFA]"
                  style="text-decoration: none"
                  >Privacy Policy</a
                >
              </div>
            </div>
          </div>

          <!-- Bottom Stack -->
          <div class="tw-flex tw-items-start tw-pt-8" style="border-top: 1px solid #e0f2fe">
            <p class="tw-text-[#F9F9F9] tw-text-md-regular tw-font-sans">
              © 2025 Slater and Gordon UK. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <footer
        ref="footerRef"
        class="footer w-100 h-auto d-block tw-mt-16"
        :style="'background-color: ' + theme.headerFooterColour + '; '"
      >
        <div class="container-fluid">
          <b-row class="justify-content-center">
            <!-- Quick Solution: Display Trustpilot PNG on Milberg Funnel Only -->
            <template v-if="onMilbergFunnel">
              <b-col class="text-center mb-4" cols="12">
                <img
                  :src="require('@/assets/images/trustpilotMilberg.png')"
                  height="42"
                  alt="Trustpilot Score"
                  class="mx-4"
                />
              </b-col>
            </template>

            <!-- Workspace Footer -->
            <template v-if="onWorkspaceBrandedPage && navigationFooter && navigationFooter.length > 0">
              <b-col cols="12" lg="6" xl="4" class="mb-4">
                <p class="font-weight-light text-left mb-0 d-block" :style="{ color: theme.headerFooterTextColour }">
                  {{ navigationFooter }}
                </p>

                <!-- Complaints Policy Milberg -->
                <div
                  v-if="onMilbergFunnel"
                  class="font-weight-light text-left mb-0 d-block mt-2 d-flex flex-column"
                  :style="{ color: theme.headerFooterTextColour }"
                >
                  <a href="https://milberg.co.uk/complaints/" class="text-secondary" target="_blank">
                    Complaints Policy</a
                  >
                  <a
                    href="https://milberg.co.uk/client-data-protection-statement/"
                    class="text-secondary"
                    target="_blank"
                    >Client Data Protection Statement</a
                  >
                </div>
                <div
                  v-if="onFinanceFunnel"
                  class="font-weight-light text-left mb-0 d-block mt-2 d-flex flex-column"
                  :style="{ color: theme.headerFooterTextColour }"
                >
                  <a
                    href="https://thefinanceclaim.co.uk/complaints-handling-policy"
                    class="text-secondary"
                    target="_blank"
                    >Complaints Handling Policy</a
                  >
                  <a href="https://thefinanceclaim.co.uk/privacy-policy" class="text-secondary" target="_blank"
                    >Privacy Policy</a
                  >
                  <a
                    href="https://cdn.claimsgate.co.uk/maddisonclarke/Maddison+Clarke+TFC+-+Cancellation+Form.pdf"
                    class="text-secondary"
                    target="_blank"
                  >
                    Cancellation Policy</a
                  >
                </div>

                <!-- Complaints Policy LLHR -->
                <div
                  v-if="onLLHRFunnel"
                  class="font-weight-light text-left mb-0 d-block mt-2"
                  :style="{ color: theme.headerFooterTextColour }"
                >
                  <a
                    href="https://llhr-law.com/complaints-information-statement/"
                    class="text-secondary"
                    target="_blank"
                  >
                    Complaints Information Statement</a
                  >
                </div>
              </b-col>
            </template>

            <!-- SRA Badge -->
            <template v-if="onWorkspaceBrandedPage && navigationDisplaySRATag && !onClaimsGateWebsite">
              <b-col class="text-center pb-4" cols="12" lg="6" xl="4">
                <div style="max-width: 275px; max-height: 163px; margin: auto">
                  <div style="position: relative; padding-bottom: 59.1%; height: auto; overflow: hidden">
                    <iframe
                      frameborder="0"
                      scrolling="no"
                      allowTransparency="true"
                      src="https://cdn.yoshki.com/iframe/55847r.html"
                      style="
                        border: 0px;
                        margin: 0px;
                        padding: 0px;
                        backgroundcolor: transparent;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                      "
                    ></iframe>
                  </div>
                </div>
              </b-col>
            </template>

            <!-- CG Affiliate Badge -->
            <template>
              <b-col cols="12" lg="6" xl="4" class="mb-4">
                <AffiliateBadge />
              </b-col>
            </template>
          </b-row>
        </div>
      </footer>
    </template>
  </div>
  <!-- <Footer /> -->
</template>
