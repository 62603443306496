<script lang="ts">
// General
import Vue from "vue";

// Plugins
import Modal from "./informationModalPlugin.js";

//Types
import { InfoModalType, InfoModalIcon, InfoModalParams } from "@claimsgate/core-types";

// Wrappers
import ModalBodyWrapper from "@/components/shared/wrappers/modalBodyWrapper.vue";

// Vue X
import { permissionsComputed } from "@/state/helpers";

export default Vue.extend({
  name: "InformationModal",
  components: {
    ModalBodyWrapper,
  },
  data() {
    return {
      // possible types: info, warning, error, delete
      type: "error" as InfoModalType,

      /** This variable determines whether the modal is currently visible or not (as the modal is present on every vue component by default) */
      visible: false,
      /** Determines whether the user pressed on of the button inside the modal
       * Used to determine if modal was closed using a button press or dismissed*/
      buttonPressed: false,

      /** Variables indicating whether one of the callback funcions is currently in progress, succeded, or failed */
      showLoader: false,
      showSuccess: false,
      showFail: false,

      /** Defines whether currently selected theme should be applied to the InfoModal */
      useTheme: true,

      /** Icon to be presented at the top of the modal */
      icon: "" as InfoModalIcon,

      /** Variables indicating what text should be displayed in different parts within the modal */
      title: "",
      text: "",
      dismissButtonText: "",
      cancelButtonText: "",
      confirmButtonText: "",
      /** If true when the user clicks off the modal, on hide function will not be called */
      noActionOnDismiss: false,
      /** These are Promise related function in case the modal interaction needs to be awaited */
      resolve: {},
      reject: {},

      /** This is a callback function which executes when the user hides the modal */
      onHide: {} as Function | {},
      /** This is a callback function which executes when the user presses the confirm button */
      onConfirm: {} as Function | {},
      /** If true, the modal can't be closed by clicking outside it */
      noCloseOnBackDrop: false,
      /** If the cancel button should be displayed */
      showCancelButton: true,
    };
  },
  methods: {
    /** This function is called at the end of all other methods that close the modal - dismiss/hide/confirm  */
    complete(result) {
      this.visible = false;
      if (typeof this.resolve === "function") {
        let returnObject = {};
        if (result) {
          returnObject = { isConfirmed: true };
        }

        if (!result) {
          returnObject = { isConfirmed: false };
        }

        this.resolve(returnObject);
      }
    },

    /** This function is called when modal has been dismissed (not via a button press) */
    dismiss() {
      if (this.noActionOnDismiss) {
        this.complete(false);
        return;
      }
      if (!this.buttonPressed) {
        if (typeof this.onHide === "function") {
          this.onHide();
        }
        this.complete(false);
      }
    },

    /** This function is called when 'Cancel/Understood' button has been pressed */
    hide() {
      // method for closing modal
      this.buttonPressed = true;
      if (typeof this.onHide === "function") {
        this.onHide();
      }
      this.complete(false);
    },

    /** This function is called when 'Confirm' button has been pressed */
    async confirm() {
      this.buttonPressed = true;
      if (typeof this.onConfirm === "function") {
        try {
          // function
          // ? the function might not be async but we await just in case it is
          this.showLoader = true;
          await this.onConfirm();
          this.showLoader = false;

          // success ? sometimes the tick is not showed ??
          this.showSuccess = true;
          await new Promise((resolve) => setTimeout(resolve, 700));
          this.showSuccess = false;
        } catch (exception) {
          // fail
          this.showFail = true;
          await new Promise((resolve) => setTimeout(resolve, 700));
          this.showFail = false;
        }
      }

      this.complete(true);
    },

    /** This function is called when and evenBus 'show' event is received.
     * Sets all the data in the modal from the params passed and makes the modal visible */
    show(params: InfoModalParams) {
      this.buttonPressed = false;
      this.useTheme = params.useTheme ?? true;
      this.type = params.type ?? "error";

      this.icon = params.icon ?? this.type ?? "error";
      this.title = params.title ?? this.type.charAt(0).toUpperCase() + this.type.slice(1);
      this.text = params.text ?? "";

      this.dismissButtonText = params.dismissButtonText ?? "Understood";
      this.cancelButtonText = params.cancelButtonText ?? "Cancel";
      this.confirmButtonText = params.confirmButtonText ?? "Confirm";
      this.noActionOnDismiss = params.noActionOnDismiss ?? false;
      // setting callback function
      this.onHide = params.onHide ?? {};
      this.onConfirm = params.onConfirm ?? {};

      this.noCloseOnBackDrop = !!params.noCloseOnBackDrop;
      this.showCancelButton = params.showCancelButton !== undefined ? params.showCancelButton : this.showCancelButton;
      this.showLoader = params.showLoader !== undefined ? params.showLoader : this.showLoader;
      this.visible = true;
    },
  },
  computed: {
    ...permissionsComputed,
  },
  mounted() {
    Modal.EventBus.$on("show", (params, resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      this.show(params);
    });
  },
  destroyed() {
    Modal.EventBus.$off("show");
  },
});
</script>

<style>
.modal {
  overflow-y: inherit;
}

.modal-open {
  overflow: inherit;
  padding-right: 0px !important;
}
</style>

<template>
  <div>
    <b-modal
      id="InformationModal"
      ref="InformationModal"
      hide-header
      hide-footer
      centered
      size="display-xs"
      v-model="visible"
      @hide="dismiss()"
      :no-close-on-backdrop="noCloseOnBackDrop"
    >
      <ModalBodyWrapper :useTheme="useTheme">
        <!-- Icon -->
        <template v-if="icon === 'warning'">
          <font-awesome-icon icon="question" class="mb-2" style="display: inline-block; width: 100%; font-size: 40px" />
        </template>
        <template v-else-if="icon === 'info'">
          <div class="d-flex justify-content-center">
            <b-iconstack font-scale="4.5" class="justify-content-center mb-2">
              <b-icon stacked icon="circle-fill" class="text-grey-50" />
              <b-icon stacked icon="circle-fill" scale="0.75" class="text-grey-100" />
              <b-icon stacked icon="info-circle-fill" scale="0.5" class="text-grey-500" />
            </b-iconstack>
          </div>
        </template>
        <template v-else-if="icon === 'error'">
          <div class="d-flex justify-content-center">
            <b-iconstack font-scale="4.5" class="justify-content-center mb-2">
              <b-icon stacked icon="circle-fill" class="text-grey-50" />
              <b-icon stacked icon="circle-fill" scale="0.75" class="text-grey-100" />
              <b-icon stacked icon="info-circle-fill" scale="0.5" class="text-grey-500" />
            </b-iconstack>
          </div>
        </template>
        <template v-else-if="icon === 'delete'">
          <div class="d-flex justify-content-center">
            <b-iconstack font-scale="4.5" class="justify-content-center mb-2">
              <b-icon stacked icon="circle-fill" class="text-danger-50" />
              <b-icon stacked icon="circle-fill" scale="0.75" class="text-danger-100" />
              <b-icon stacked icon="x-circle-fill" scale="0.5" class="text-danger-500" />
            </b-iconstack>
          </div>
        </template>
        <template v-else-if="icon === 'success'">
          <font-awesome-icon icon="check" class="mb-2" style="display: inline-block; width: 100%; font-size: 40px" />
        </template>

        <!-- Title -->

        <h3 class="text-center text-grey-900 text-lg font-weight-semibold">{{ title }}</h3>

        <!-- Body -->
        <p class="mb-4 text-center text-grey-700 text-sm">
          {{ text }}
        </p>

        <!-- Info Buttons -->
        <template v-if="type === 'info'">
          <b-button @click="hide()" size="lg" variant="light" block>
            <template v-if="showFail">
              <font-awesome-icon icon="times" />
            </template>
            <template v-else-if="showSuccess">
              <font-awesome-icon icon="check" />
            </template>
            <template v-else-if="showLoader">
              <b-spinner small role="status" class="mb-1"></b-spinner>
            </template>
            <template v-else>
              {{ dismissButtonText ? dismissButtonText : confirmButtonText }}
            </template>
          </b-button>
        </template>

        <!-- Warning Buttons -->
        <template v-else-if="type === 'warning'">
          <b-button @click="hide()" size="lg" variant="light" style="float: left; width: 50%" v-if="showCancelButton">
            <template>{{ cancelButtonText }}</template>
          </b-button>

          <b-button
            @click="confirm()"
            size="lg"
            variant="primary"
            :block="!showCancelButton"
            :style="{ width: showCancelButton ? '49%' : '100%' }"
            :class="showCancelButton ? 'float-right' : ''"
          >
            <template v-if="showFail">
              <font-awesome-icon icon="times" />
            </template>
            <template v-else-if="showSuccess">
              <font-awesome-icon icon="check" />
            </template>
            <template v-else-if="showLoader">
              <b-spinner small role="status" class="mb-1"></b-spinner>
            </template>
            <template v-else>
              {{ confirmButtonText }}
            </template>
          </b-button>
        </template>

        <!-- Delete Buttons -->
        <template v-else-if="type === 'delete'">
          <b-button @click="hide()" size="lg" variant="light " style="float: left; width: 50%" v-if="showCancelButton">
            {{ cancelButtonText }}
          </b-button>

          <b-button
            @click="confirm()"
            size="lg"
            variant="danger"
            :block="!showCancelButton"
            :style="{ width: showCancelButton ? '49%' : '100%' }"
            :class="showCancelButton ? 'float-right' : ''"
          >
            <template v-if="showFail">
              <font-awesome-icon icon="times" />
            </template>
            <template v-else-if="showSuccess">
              <font-awesome-icon icon="check" />
            </template>
            <template v-else-if="showLoader">
              <b-spinner small role="status" class="mb-1"></b-spinner>
            </template>
            <template v-else>
              {{ confirmButtonText }}
            </template>
          </b-button>
        </template>

        <!-- Error Buttons -->
        <template v-else-if="type === 'error'">
          <b-button @click="hide()" size="lg" variant="light" block>
            <template v-if="showFail">
              <font-awesome-icon icon="times" />
            </template>
            <template v-else-if="showSuccess">
              <font-awesome-icon icon="check" />
            </template>
            <template v-else-if="showLoader">
              <b-spinner small role="status" class="mb-1"></b-spinner>
            </template>
            <template v-else>
              {{ dismissButtonText ? dismissButtonText : confirmButtonText }}
            </template>
          </b-button>
        </template>

        <!-- Success Buttons -->
        <template v-else-if="type === 'success'">
          <b-button @click="hide()" size="lg" variant="light" block>
            <template v-if="showFail">
              <font-awesome-icon icon="times" />
            </template>
            <template v-else-if="showSuccess">
              <font-awesome-icon icon="check" />
            </template>
            <template v-else-if="showLoader">
              <b-spinner small role="status" class="mb-1"></b-spinner>
            </template>
            <template v-else>
              {{ dismissButtonText ? dismissButtonText : confirmButtonText }}
            </template>
          </b-button>
        </template>
      </ModalBodyWrapper>
    </b-modal>
  </div>
</template>
