<script lang="ts">
// General
import Vue from "vue";

import { layoutComputed } from "@/state/helpers";
import Horizontal from "./horizontal.vue";

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    builder: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    navDirection: {
      type: String,
      required: false,
    },
    removePadding: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  components: { Horizontal },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },
  methods: {},
});
</script>

<template>
  <div>
    <InfoModal />

    <template v-if="navDirection === 'vertical'">
      <div :class="`d-flex flex-column flex-md-row`">
        <slot name="sidebar" />

        <b-container
          :style="removePadding ? 'overflow-x: auto' : 'margin-top: 32px; overflow-x: auto'"
          class="px-5"
          fluid
        >
          <slot />
        </b-container>
      </div>
    </template>

    <Horizontal v-else-if="layoutType === 'horizontal'" :layout="layoutType" :loading="loading" :fullWidth="fullWidth">
      <!-- <template v-slot:nav>
        <slot name="nav" />
      </template> -->
      <template v-slot:blocks>
        <slot name="blocks" />
      </template>
      <slot />
    </Horizontal>
  </div>
</template>
